<template>
  <v-container>
        <div class="mt-10 mb-3 text-h5">{{ $t('Panel Create Ad Title') }}</div>
        <div class="mb-3">{{ $t('Panel Create Ad Subtitle') }}</div>
        <help-center-link topic="create-apartment" link-text="Kako dodati novi oglas?" class="mb-5"/>

        <div class="d-flex flex-wrap ">
            <v-card
              :max-width="$vuetify.breakpoint.smAndUp ? 255 : ''"
              v-for="category in categories"
              :key="category.const"
              outlined
              rounded
              class="pa-5 mr-sm-5 mb-5">
                <div class="d-flex justify-center">
                  <v-img
                    :src="category.icon"
                    :lazy-src="category.icon"
                    :alt="category.singular[$i18n.locale]"
                    max-height="50"
                    max-width="50"
                  >
                  </v-img>
                </div>
                <v-card-title class="justify-center">
                  {{category.singular[$i18n.locale]}}
                </v-card-title>
                <v-card-subtitle
                  v-if="category.description_panel[$i18n.locale]"
                  class="text-center">
                    {{category.description_panel[$i18n.locale]}}
                </v-card-subtitle>
                <v-card-actions class="justify-center">
                  <v-btn
                    text
                    outlined
                    :to="{ name: 'AdFormCreate', params: { categoryConst: category.const }}"
                    >{{$t('List your property')}}</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-container>
</template>
<script>
import MetaTitleMixin from '@/lib/MetaTitleMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';

export default {
  mixins: [MetaTitleMixin],
  components: {
    HelpCenterLink,
  },
  title() {
    return this.setMetaTitle();
  },
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.$store.dispatch('setSilverBackgroundColor');
    // Get categories
    this.$store
      .dispatch('getAdCategories')
      .then((categories) => {
        this.categories = categories;
      });
  },
  methods: {
    setMetaTitle() {
      this.$title = this.$t('Listings Create SEO Title');
    },
  },
  destroyed() {
    this.$store.dispatch('removeBackgroundColor');
  },
};
</script>
